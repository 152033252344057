import * as React from "react";
import { BaseApp } from "@templates";
import {
    Link,
    Typography,
    Container,
    Row,
    Col,
    Spacer,
    Svg
} from "@proxiserve-ui-components";
import File404 from "@images/404.svg";

const NotFoundPage = () => {
    return (
        <BaseApp>
            <Spacer mb="40px" md={{mb: "200px"}}></Spacer>
            <Container>
                <Row xs={{ alignItems: "center"}}>
                    <Col md={5}>
                        <Svg data={File404} type="image/svg+xml" aria-label="404"></Svg>
                    </Col>
                    <Col md={7}>
                        <Typography balise="p" variant="h3" family="text" weight="400">
                            Désolés, l’information que vous cherchez ne se
                            trouve pas sur cette page !
                        </Typography>
                        <Spacer mb={"15px"}></Spacer>
                        <Link asButton to="/">
                            Aller à la page d’accueil
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Spacer mb="40px"md={{mb: "200px"}}></Spacer>
        </BaseApp>
    );
};

export default NotFoundPage;
